<template>
  <div v-if="contacts?.length ?? 0 > 0" class="floating">
    <div v-for="(item, indexSubMenu) in contacts" :key="indexSubMenu" class="floating__item">
      <NuxtLink :to="item.url" target="_blank" class="floating__item--image">
        <BaseImg v-if="!isBotChromeLighthouse" class="icon icon-bottom" :src="item.img" :alt="item?.display_name" />
        <div class="tooltip">
          <div class="tooltip-content">
            <span class="description">{{ item?.display_name }}</span>
          </div>
          <div class="arrow-down" />
        </div>
      </NuxtLink>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useAppStore } from '~/store/app'
const { isBotChromeLighthouse } = useAppStore()
const { fetchBranchContact } = useContact()
const { data: contacts } = await useAsyncData('contacts', async () => {
  return await fetchBranchContact()
})
</script>
<style lang="scss" scoped src="public/assets/scss/components/desktop/floating-menu/index.scss" />
